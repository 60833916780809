import { FETCH_LOCALCOINS, LIKE_LOCALCOIN, LOCALCOINS_START_LOADING, LOCALCOINS_END_LOADING } from '../constants/actionTypes';

const localCoins = (state = { isLoading: true, localCoins: [] }, action) => {
    switch (action.type) {
        case FETCH_LOCALCOINS:
            return { ...state, localCoins: action.payload };
        case LIKE_LOCALCOIN :
            if (state?.localCoins?.length > 0) return state?.localCoins?.map(function (coin) {
                if (coin.uuid === action.payload.uuid) return { ...state, localCoins: [action.payload] }
                else return state
            })[0]
            else return state
        case LOCALCOINS_START_LOADING:
            return { ...state, isLoading: true };
        case LOCALCOINS_END_LOADING:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}

export default localCoins;