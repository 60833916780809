import React from 'react'
import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Typography, Radio, Table, Carousel } from 'antd';
import millify from 'millify';

import LightWeightChartx from './LightWeightChartx';
import Loader from './Loader';
import { useGetStatsQuery, useGetMarketCapHistoryQuery, useGetHistoryQuery } from '../services/cryptoQuery';
import { getLunarTopCoins } from '../actions/lunarCoins'
import { getLocalCoins } from '../actions/localCoins';
import { FormatHistory } from './dataFormat';
import Cryptolist from './Cryptolist';

const {Text, Title} = Typography;

const Home = () => {
  const treeMapImg = `https://res.cloudinary.com/dswiezofp/image/upload/v1717617973/socoins/treemap.png?v=${new Date().getTime()}`
  
  console.log(new Date().getTime())

  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [coinId, setCoinId] = useState('Qwsogvtv82FCd')
  const time = ["1h", "24h"]
  const [timePeriod, setTimePeriod] = useState("24h")
  const [history, setHistory] = useState()
  const bestCoinsColData = []
  const newCoinsColData = []
  const topCoinsColData = []

  useEffect(() => {
    dispatch(getLocalCoins(null, 1000));
    dispatch(getLunarTopCoins(3, 0))
  }, [], dispatch);

  const {localCoins, localCoinsLoading} = useSelector((state) => state.reducers.localCoins);
  const {lunarTopCoins, lunarTopCoinsLoading} = useSelector((state) => state.reducers.lunarCoins);
  const currency = useSelector((state) => state.reducers.setCurrency.value) 

  const { data : globalStats, isFetching : globalStatsFetching } = useGetStatsQuery(currency);
  const { data: historyData, isFetching : historyFetching } = useGetHistoryQuery({coinId, timePeriod, currency});

  const coinName = localCoins?.find(e => e.uuid === coinId)?.name
  //const { data : marketCapHistory, isFetching : marketCapHistoryFetching } = useGetMarketCapHistoryQuery(coinId); //marche pas pour btc not found pb coinranking

  //const redioCryptos = localCoins?.slice(0,7).map(function(coin) {
  //    return {key : coin.uuid, symbol : coin.symbol }
  //})

  const generalStats = [
    {key: 'totalCoins', title: 'Total Coins'},
    {key: 'totalExchanges', title: 'Total Exchanges'},
    {key: 'totalMarketCap', title: 'Total MarketCap'},
    {key: 'btcDominance', title: 'BTC Dominance'}
  ];

  const radioCoins = [
    {key: 'Qwsogvtv82FCd'},
    {key: 'razxDUgYGNAdQ'},
    {key: 'zNZHO_Sjf'},
    {key: 'WcwrkfNI4FUAe'},
    {key: 'a91GCGd_u96cF'},
    {key: '-l8Mn2pVlRs-p'},
    {key: 'qzawljRxB5bYu'},
    {key: 'dvUj0CzDZ'},
    {key: 'VLqpJwogdhHNb'},
    {key: 'qUhEFk1I61atv'},
    {key: 'uW2tk-ILY0ii'},
    {key: '25W7FG7om'},
    {key: '03WI8NQPF'},
    {key: '_H5FVG9iW'},
    {key: 'D7B1x_ks7WhV5'},
  ];

  const bestCoinsCol = [
    { dataIndex:'uuid', key:'uuid', align:'center', hidden: true },
    { title:'Rank', dataIndex:'rank', key:'rank', align:'center' },
    { title:'Logo', dataIndex:'logo', key:'logo', align:'center', render:logo => (<img className="home-best-coins-logo" src={logo}/>) },
    { title:'Symbol', dataIndex:'symbol', key:'symbol', align:'center' },
    { title:'Price', dataIndex:'price', key:'price', align:'center', render:price => (Number(price).toFixed(2))},
    { title:'Change', dataIndex:'change', key:'change', align:'center', render:change => (Number(change).toFixed(2) + "%")},
  ]
  const newCoinsCol = [
    { dataIndex:'uuid', key:'uuid', align:'center', hidden: true },
    { title:'Rank', dataIndex:'rank', key:'rank', align:'center' },
    { title:'Logo', dataIndex:'logo', key:'logo', align:'center', render:logo => (<img className="home-best-coins-logo" src={logo}/>) },
    { title:'Symbol', dataIndex:'symbol', key:'symbol', align:'center' },
    { title:'Name', dataIndex:'name', key:'name', align:'center' },
  ]
  const topCoinsCol = [
    { dataIndex:'uuid', key:'uuid', align:'center', hidden: true },
    { title:'Rank', dataIndex:'rank', key:'rank', align:'center' },
    { title:'Logo', dataIndex:'logo', key:'logo', align:'center', render:logo => (<img className="home-best-coins-logo" src={logo}/>) },
    { title:'Symbol', dataIndex:'symbol', key:'symbol', align:'center' },
    { title:'Name', dataIndex:'name', key:'name', align:'center' },
    { title:'Interactions', dataIndex:'interactions', key:'interactions', align:'center', render:interactions => (millify(interactions))},
    { title:'Change', dataIndex:'change', key:'change', align:'center', render:change => (Number(change).toFixed(2) + "%")},
  ]

    globalStats?.data?.bestCoins?.map((coin, n=0) => (
      bestCoinsColData.push({
        key: coin?.uuid,
        uuid: coin?.uuid,
        rank: n+1,
        logo: coin?.iconUrl,
        symbol: coin?.symbol,
        price: localCoins?.find(e => e.uuid === coin?.uuid)?.price ? localCoins?.find(e => e.uuid === coin?.uuid)?.price : 'N.A',
        change: localCoins?.find(e => e.uuid === coin?.uuid)?.change ? localCoins?.find(e => e.uuid === coin?.uuid)?.change : 'N.A',
      })
    ))

    globalStats?.data?.bestCoins?.map((coin, n=0) => (
      newCoinsColData.push({
        key: coin?.uuid,
        uuid: coin?.uuid,
        rank: n+1,
        logo: coin?.iconUrl,
        symbol: coin?.symbol,
        name: coin?.name,
      })
    ))

    lunarTopCoins?.map((coin, n=0) => (
      topCoinsColData.push({
        key: coin?.name,
        uuid: localCoins? localCoins?.filter(e => e.symbol === coin.name)[0]?.uuid : '-',
        rank: n+1,
        logo: localCoins? localCoins?.filter(e => e.symbol === coin.name)[0]?.iconUrl : '-',
        symbol: localCoins? localCoins?.filter(e => e.symbol === coin.name)[0]?.symbol : '-',
        name: localCoins? localCoins?.filter(e => e.symbol === coin.name)[0]?.name : '-',
        interactions: coin?.current?.data[0]?.interactions_24h,
        change: ((coin?.current?.data[0]?.interactions_24h - coin?.previous?.data[0]?.interactions_24h) / coin?.current?.data[0]?.interactions_24h) * 100
      })
    ))

  useEffect(() => {
    historyFetching? (<Loader/>) : (setHistory(FormatHistory(historyData)))
  }, [/*refresh,*/ location, historyData, historyFetching]);

  if (globalStatsFetching===true) return <Loader/>
  
  return (
    <>
      <Row gutter={[4,4]} justify="center">
        <Col xl={12} xs={24}>
          <Row>
            {generalStats?.map(({key, title}) => (
              <Col xl={6} sm={6} xs={12} key={key} className='home-global-stats-container'>
                <Row className='home-global-stats'>
                  <Typography.Text >{<p className='home-global-stats-text'>{title}</p>}</Typography.Text>
                  <Typography.Text strong><p className='home-global-stats-value'>&nbsp;{millify(globalStats?.data[key])}</p></Typography.Text>
                  <Typography.Text strong><p className='home-global-stats-value'>{key === "btcDominance" ? "%" : ""}</p></Typography.Text>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row gutter={[32,4]} justify="center" className='home-card-container'>
      
        <Col xl={8} xs={24}>
          <Col className='home-card'>
            <Carousel>
              <div>
                <div className='home-card-carousel'>
                  <Col className='home-card-heading'>
                    <Title level={4} className="home-card-title">Best Performing</Title>
                  </Col>
                  <Table className="home-best-coins-table" dataSource={bestCoinsColData} columns={bestCoinsCol} showHeader={false} pagination={false} onRow={(record, rowIndex) => { return {onClick: event => navigate(`/coinpage/${record.uuid}`)}}} style={{cursor: "pointer"}}/>
                </div>
              </div>
              <div>
                <div className='home-card-carousel'>
                  <Col className='home-card-heading'>
                    <Title level={4} className="home-card-title">New Coins</Title>
                  </Col>
                  <Table className="home-best-coins-table" dataSource={newCoinsColData} columns={newCoinsCol} showHeader={false} pagination={false} onRow={(record, rowIndex) => { return {onClick: event => navigate(`/coinpage/${record.uuid}`)}}} style={{cursor: "pointer"}}/>
                </div>
              </div>
            </Carousel>
          </Col>
        </Col>

        <Col xl={8} xs={24}>
          <Carousel>
            <Col className='home-card'>
              <Col className='home-card-heading'>
                <Title level={4} className="home-card-title">Social Interactions</Title>
              </Col>
              <Table className="home-best-coins-table" dataSource={topCoinsColData} columns={topCoinsCol} showHeader={false} pagination={false} onRow={(record, rowIndex) => { return {onClick: event => navigate(`/coinpage/${record.uuid}`)}}} style={{cursor: "pointer"}}/>
              <Col className='home-best-coins-more'><Link to={`/topcoins`}><Text className='home-best-coins-more-text'>See More</Text></Link></Col>
            </Col> 
          </Carousel> 
        </Col>

        <Col xl={8} xs={24}>
            <Col className='home-card'>
              <Link to={`/treemap`}>
                <img className="treemap-img" src={treeMapImg}/>
              </Link>
            </Col>
        </Col>
      
      </Row>

      <Row>
            <Cryptolist simplified/>
      </Row>

      <Row gutter={[4,4]} className='home-chart'>
        <Col xl={24} xs={24}>
          <Row className='home-selector-header'>
            <Col xl={18} xs={24} className='home-selector-header-col'>
              <Radio.Group className='home-select-coin' onChange={e => e.target.value === "more" ? navigate(`/cryptolist`) : setCoinId(e.target.value)} defaultValue={coinId}>
                {radioCoins?.map(({key}) => (
                  <Radio.Button key={key} value={key}>{localCoins?.find(e => e.uuid === key)?.symbol}</Radio.Button>
                ))}
                <Radio.Button key={'more'} value={'more'}>...</Radio.Button>
              </Radio.Group>
            </Col>
            <Col xl={6} xs={24} className='home-selector-header-col'>
              <Radio.Group defaultValue={timePeriod} className='home-select-time' onChange={e => e.target.value === "more" ? navigate(`/coinpage/${coinId}`) : setTimePeriod(e.target.value)}>
                {time?.map((time) => (
                  <Radio.Button key={time} value={time}>{time}</Radio.Button>
                ))}
                <Radio.Button key={'more'} value={'more'}>...</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

            {history ? (
              <LightWeightChartx data = {history} coinName={coinName} delta = {300} className="crypto-chart"></LightWeightChartx>
            )  : ( <Loader />                    
            )}

          </Col>
      </Row>
    </>
  )
}

export default Home