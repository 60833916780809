import { LUNARWEEK_V2_START_LOADING, FETCH_LUNAR_COIN_WEEK_V2, LUNARWEEK_V2_END_LOADING, LUNARYEAR_V2_START_LOADING, FETCH_LUNAR_COIN_YEAR_V2, LUNARYEAR_V2_END_LOADING, LUNAR_LIST_START_LOADING, FETCH_LUNAR_COINS_LIST, LUNAR_LIST_END_LOADING, LUNAR_LIST_AGG_START_LOADING, FETCH_LUNAR_COINS_LIST_AGG, LUNAR_LIST_AGG_END_LOADING, LUNAR_TOPCOINS_START_LOADING, FETCH_LUNAR_TOPCOINS, LUNAR_TOPCOINS_END_LOADING } from '../constants/actionTypes';

const lunarCoins = (state = { lunarWeekLoadingV2: true, lunarYearLoadingV2: true, lunarCoinWeekV2 : [], lunarCoinYearV2 : [], lunarCoinsList : [], lunarCoinsListAgg : [], lunarTopCoins: [] }, action) => {
    switch (action.type) {
      case LUNARWEEK_V2_START_LOADING:
          return { ...state, lunarWeekLoadingV2: true };
      case LUNARWEEK_V2_END_LOADING:
          return { ...state, lunarWeekLoadingV2: false };
      case LUNARYEAR_V2_START_LOADING:
          return { ...state, lunarYearLoadingV2: true };
      case LUNARYEAR_V2_END_LOADING:
          return { ...state, lunarYearLoadingV2: false };
      case LUNAR_LIST_START_LOADING:
          return { ...state, LunarListLoading: true };
      case LUNAR_LIST_END_LOADING:
          return { ...state, LunarListLoading: false };
      case LUNAR_LIST_AGG_START_LOADING:
          return { ...state, LunarListAggLoading: true };
      case LUNAR_LIST_AGG_END_LOADING:
          return { ...state, LunarListAggLoading: false };
      case LUNAR_TOPCOINS_START_LOADING:
          return { ...state, LunarTopCoinsLoading: true };
      case LUNAR_TOPCOINS_END_LOADING:
          return { ...state, LunarTopCoinsLoading: false };
      case FETCH_LUNAR_COIN_WEEK_V2:
          return { ...state, lunarCoinWeekV2: action.payload };
      case FETCH_LUNAR_COIN_YEAR_V2:
          return { ...state, lunarCoinYearV2: action.payload };
      case FETCH_LUNAR_COINS_LIST:
          return { ...state, lunarCoinsList: action.payload };
      case FETCH_LUNAR_COINS_LIST_AGG:
          return { ...state, lunarCoinsListAgg: action.payload };
      case FETCH_LUNAR_TOPCOINS:
          return { ...state, lunarTopCoins: action.payload };
      default:
        return state;
    }
  };

  export default lunarCoins;