import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, DatePicker, Space, Input, Typography } from 'antd';
import { CalendarOutlined, StockOutlined, DollarCircleOutlined } from '@ant-design/icons';
import Loader from './Loader';
import {NumericFormat} from 'react-number-format';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getAllTimeHistory } from '../actions/allTimeHistory';
import LightWeightChartx from './LightWeightChartx';

dayjs.extend(customParseFormat);

const { Title, Text } = Typography;

function convertDate(date) {
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth()+1).toString();
  var dd  = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
}


//Bitcoin Price Since 2009. cette phrase doit être dedans pour le référencement car elle n'exsite nulle part

const WhatIf = () => {
    const dispatch = useDispatch();
    const name = 'Qwsogvtv82FCd'
    const [historyData, setHistoryData] = useState()
    const [lastData, setLastData] = useState()
    const [quantity, setQuantity] = useState(100)
    const [selectedDate, setSelectedDate] = useState()
    const [totalValue, setTotalValue] = useState()
    const [dcaValue, setDcaValue] = useState()
    const [minimumDate, setMinimumDate] = useState()
    const [defaultDate, setDefaultDate] = useState()
    const [allTimeHistoryFormat, setAllTimeHistoryFormat] = useState()

    useEffect(() => {
      if (name) {
        dispatch(getAllTimeHistory(name))
      }
    }, [name], dispatch);

    const allTimeHistory = useSelector((state) => state?.reducers?.allTimeHistory);

    useEffect(() => {
      const formatAllTimeHistory = allTimeHistory?.allTimeHistory[0]?.data?.map(function(element) {
          return {time : element.time, value : element.close}
      })
      setAllTimeHistoryFormat(formatAllTimeHistory)
    },[allTimeHistory])
    
    useEffect(() => {
      if(allTimeHistoryFormat != undefined) {
        const firstValue = !allTimeHistoryFormat ? 'loading' : allTimeHistoryFormat.at(0)
        const firstDate = new Date(firstValue.time * 1000)
        const lastValue = !allTimeHistoryFormat ? 'loading' : allTimeHistoryFormat.slice(-1)
        setDefaultDate(String(convertDate(firstDate)))
        setMinimumDate(firstDate.getFullYear() + "-01-01")
        setLastData(lastValue[0])
      }
    }, [allTimeHistoryFormat]);

    useEffect(() => {
      if (quantity && historyData && lastData) {
          setTotalValue((quantity/historyData?.value)*(lastData?.value - historyData?.value))
      } else setTotalValue('')
    }, [quantity, historyData, lastData]);

    useEffect(() => {
      if (quantity && historyData && lastData) {
        const selectedHistory = allTimeHistoryFormat?.filter(element => {
          return element.time >= selectedDate && selectedDate <= lastData?.time
        })
        const allTimeHistoryBTC = selectedHistory?.map(function (element, n) {
          return {time : element.time, value : quantity/element.value}
        })
        const sum = allTimeHistoryBTC?.reduce((accumulator, object) => {
          return accumulator + object.value;
        }, 0);
        const dca = sum * lastData?.value
        setDcaValue(dca)
      } else setDcaValue('')
    }, [quantity, historyData, selectedDate, lastData]);



    const onStartDateChange = (date, dateString) => {
        const dateData = new Date(dateString)
        const timeStamp = dateData.getTime()/1000
        var index = allTimeHistoryFormat.findIndex(element => element.time == timeStamp);
        const history = !allTimeHistoryFormat ? 'loading' : index >= 0 ? allTimeHistoryFormat[index] : 'not available'
        setHistoryData(history)
        setSelectedDate(timeStamp)
    };

    const onEndDateChange = (date, dateString) => {
      const dateData = new Date(dateString)
      const timeStamp = dateData.getTime()/1000
      //mettre if date selectionnée est supérieure à la date de départ // sinon début de end date c'est la startdate
      var index = allTimeHistoryFormat.findIndex(element => element.time == timeStamp);
      const history = !allTimeHistoryFormat ? 'loading' : index > 0 ? allTimeHistoryFormat[index] : 'not available'
      console.log(history)
      setLastData(history)
    };

    const dateFormat = 'YYYY-MM-DD';

    if (!defaultDate || !minimumDate) return <Loader />;

    const calculationTable = [
        { key : 'worth', title: 'Worth', value: <Input size='small' defaultValue={100} className='what-if-input' suffix={'$'} onChange={(e) => setQuantity(e.target.value)} />, icon: <CalendarOutlined /> },
        { key : 'dateStart', title: 'Start', value: <DatePicker className='what-if-date' defaultValue={dayjs(defaultDate, dateFormat)} minDate={dayjs(minimumDate, dateFormat)} onChange={onStartDateChange}/>, icon: <StockOutlined /> }, //à remplacer par des valeurs automatiques
        { key : 'dateEnd', title: 'End', value: <DatePicker className='what-if-date' minDate={dayjs(minimumDate, dateFormat)} onChange={onEndDateChange}/>, icon: <StockOutlined /> },
        { key : 'earnings', title: 'Earnings', value: <NumericFormat value={totalValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>, icon: <DollarCircleOutlined /> },
        { key : 'dca', title: 'DCA', value: <NumericFormat value={dcaValue} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>, icon: <DollarCircleOutlined /> },
    ];

  return (
    <>
        <Row className="what-if-container" align={'center'}> 
            {calculationTable.map(({key, icon, title, value}) => (
                <Col xl={4} xs={8} className="what-if-hilights" key={key}>
                    <Col className="what-if-hilights-name">
                      <Space>
                        <Text id="what-if-icon">{icon}</Text>
                        <Text id="what-if-text">{title}</Text>
                      </Space>
                    </Col>
                    <Col className="what-if-hilights-value">
                      <Text>{value}</Text>
                    </Col>
                </Col>
            ))}
        </Row>
        <Row>
            <Col xl={24} xs={24} className='whatif-chart-container'>
                {allTimeHistoryFormat ? (
                    <div className='whatif-chart'>
                        <LightWeightChartx data = {allTimeHistoryFormat} coinName={name} delta = {250} scrollLeft = {true} className="crypto-chart"></LightWeightChartx>
                    </div>)
                    : ( <Loader />                    
                )}
            </Col>
        </Row>
        <Row>
            
        </Row>
    </>
  )
}

export default WhatIf