import { configureStore } from '@reduxjs/toolkit';
import reduxWebsocket from '@giantmachines/redux-websocket';

import { cryptoQuery } from  '../services/cryptoQuery'; //seule à garder pour cryptos
import { reducers } from '../reducers';

const reduxWebsocketMiddleware = reduxWebsocket(
    /*{serializer: (payload : any) => 'String'}*/
) //il reste à sérialiser levent, il ya une option serialize sur redux websocket mais je reçois pas de messages le cas échéant
    
    //dateSerializer: (date: Date) => string
    //prefix: 'REDUX_WEBSOCKET', Marche pas
    //reconnectInterval: 1000,
    //dateSerializer : '(date: Date) => string' Marche pas

export default configureStore({
    reducer : {
        [cryptoQuery.reducerPath]: cryptoQuery.reducer, //seule à garder pour cryptos // a supprimer et garder celle d'en haut
        reducers
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}).concat(cryptoQuery.middleware, reduxWebsocketMiddleware), //serializableCheck: false parce que le message du websocket qui rentre dans le reducer est non serializable et le date serializer ci-dessus ne marche pas
    });