export const FormatHistory = (data) => {

    const cryptoHistory = [...data.data.history]
    const sortedHistory = cryptoHistory?.sort(function(a,b){
        return parseFloat(a.timestamp) - parseFloat(b.timestamp)})
    const historyValues = sortedHistory?.map(({ price, timestamp }) => {
        var d = new Date(0)
        d.setUTCSeconds(timestamp)
        var timeZoneOffset = d.getTimezoneOffset()
        return { time: d.getTime()/1000 - timeZoneOffset*60, value: parseFloat(price,10) }})
    const filtredHistory = historyValues?.filter(function(item) {
        return item.value !== null})
    const history = filtredHistory?.filter(function(item) {
        return !Number.isNaN(item.value)})
    return history
    
};

export const FormatOhlc = (data) => {

    const cryptoOhlc = [...data.data.ohlc]
    const ohlcValues = cryptoOhlc?.map(({ startingAt, open, high, low, close }) => {
        var d = new Date(0)
        d.setUTCSeconds(startingAt)
        var timeZoneOffset = d.getTimezoneOffset()
        return { time: d.getTime()/1000 - timeZoneOffset*60, open: parseFloat(open,10), high: parseFloat(high,10), low: parseFloat(low,10), close: parseFloat(close,10) }})
    const sortedOhlc = ohlcValues?.sort(function(a,b){
        return parseFloat(a.time) - parseFloat(b.time)})
    const filtredHistory = sortedOhlc?.filter(function(item) {
        return item.value !== null})
    const history = filtredHistory?.filter(function(item) {
        return !Number.isNaN(item.value)})
    return history
    
};

export const FormatOhlcMa = (data) => {

    const cryptoOhlc = [...data.data.ohlc]
    const ohlcValues = cryptoOhlc?.map(({ startingAt, avg }) => {
        var d = new Date(0)
        d.setUTCSeconds(startingAt)
        var timeZoneOffset = d.getTimezoneOffset()
        return { time: d.getTime()/1000 - timeZoneOffset*60, value: parseFloat(avg,10) }})
    const sortedOhlc = ohlcValues?.sort(function(a,b){
        return parseFloat(a.time) - parseFloat(b.time)})
    const filtredHistory = sortedOhlc?.filter(function(item) {
        return item.value !== null})
    const history = filtredHistory?.filter(function(item) {
        return !Number.isNaN(item.value)})
    return history
    
};

//mettre des then à la place des variables