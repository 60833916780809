export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const GOOGLEUSER = 'GOOGLEUSER';

export const LOCALCOINS_START_LOADING = 'LOCALCOINS_START_LOADING';
export const LOCALCOINS_END_LOADING = 'LOCALCOINS_END_LOADING';
export const FETCH_LOCALCOINS = 'FETCH_LOCALCOINS';
export const LIKE_LOCALCOIN = 'LIKE_LOCALCOIN';

export const LUNAR_TOPCOINS_START_LOADING = 'LUNAR_TOPCOINS_START_LOADING';//a supprimer les 3
export const LUNAR_TOPCOINS_END_LOADING = 'LUNAR_TOPCOINS_END_LOADING';
export const FETCH_LUNAR_TOPCOINS = 'FETCH_LUNAR_TOPCOINS';

export const PORTFOLIO_START_LOADING = 'PORTFOLIO_START_LOADING';
export const PORTFOLIO_END_LOADING = 'PORTFOLIO_END_LOADING';
export const FETCH_PORTFOLIO = 'FETCH_PORTFOLIO';
export const ADD_PORTFOLIO_LINE = 'ADD_PORTFOLIO_LINE';
export const CREATE_PORTFOLIO_LINE = 'CREATE_PORTFOLIO_LINE';
export const DELETE_PORTFOLIO_LINE = 'DELETE_PORTFOLIO_LINE'
export const LIKE_PORTFOLIO_LINE = 'LIKE_PORTFOLIO_LINE';

export const SET_THEME = 'SET_THEME';
export const SET_COLORS = 'SET_COLORS';
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_TREEMAP = 'SET_TREEMAP'

export const LUNARWEEK_V2_START_LOADING = 'LUNARWEEK_V2_START_LOADING';
export const FETCH_LUNAR_COIN_WEEK_V2 = 'FETCH_LUNAR_COIN_WEEK_V2';
export const LUNARWEEK_V2_END_LOADING = 'LUNARWEEK_V2_END_LOADING';
export const LUNARYEAR_V2_START_LOADING = 'LUNARYEAR_V2_START_LOADING';
export const FETCH_LUNAR_COIN_YEAR_V2 = 'FETCH_LUNAR_COIN_YEAR_V2';
export const LUNARYEAR_V2_END_LOADING = 'LUNARYEAR_V2_END_LOADING';
export const LUNAR_LIST_START_LOADING = 'LUNAR_LIST_START_LOADING';
export const FETCH_LUNAR_COINS_LIST = 'FETCH_LUNAR_COINS_LIST';
export const LUNAR_LIST_END_LOADING = 'LUNAR_LIST_END_LOADING';
export const LUNAR_LIST_AGG_START_LOADING = 'LUNAR_LIST_AGG_START_LOADING';
export const FETCH_LUNAR_COINS_LIST_AGG = 'FETCH_LUNAR_COINS_LIST_AGG';
export const LUNAR_LIST_AGG_END_LOADING = 'LUNAR_LIST_AGG_END_LOADING';

export const FETCH_ALLTIMEHISTORY = 'FETCH_ALLTIMEHISTORY';
export const ALLTIMEHISTORY_START_LOADING = 'ALLTIMEHISTORY_START_LOADING';
export const ALLTIMEHISTORY_END_LOADING = 'ALLTIMEHISTORY_END_LOADING';

//le dispatch d'action types change l'état du state dans le store. donc ne dispatch que s'il s'agit d'une action supposée changer le même état