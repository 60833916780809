import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createChart } from "lightweight-charts";

import Loader from './Loader';

const LightWeightChart = props => {
	const chartContainerRef = useRef();
    const colors = useSelector((state) => state.reducers.setColors);

    const chartType = props.chartType
    const coinName = props.coinName;
    const data = props.data;
    const delta = props.delta;
    const scrollLeft = props.scrollLeft;
    
    useEffect( 
		() => {
            const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};

            const width = chartContainerRef.current.clientWidth;
            const height = window.innerHeight;

            const formatPrice = (price) => {
                if (price <= 0) return parseFloat(price).toFixed(0)
                if (price < 0.001) return parseFloat(price).toFixed(8)
                else if (price >= 0.001 && price < 0.1) return parseFloat(price).toFixed(6)
                else if (price >= 0.1 && price < 1) return parseFloat(price).toFixed(4)
                else return parseFloat(price).toFixed(2)
            }

			const chart = createChart(chartContainerRef.current, {
				width: width,
				height: height - delta,
                watermark: {
                  visible: true,
                  text: 'SoCoins.io', 
                  color: 'rgba(196, 29, 127, 0.3)'
                },
                layout: {
                    textColor: '#d1d4dc',
                    background: {
                        color : colors.darkPrimaryConst
                    }
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                    fixLeftEdge: scrollLeft ? false : true,
                    fixRightEdge: true,
                },
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.05,
                    },
                },
                crosshair: {
                    vertLine: {
                        width: 5,
                        color: 'rgba(224, 227, 235, 0.1)',
                        style: 0,
                    },
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
                grid: {
                    vertLines: {
                        color: 'rgba(42, 46, 57, 0)',
                    },
                    horzLines: {
                        color: 'rgba(42, 46, 57, 0)',
                    },
                },
			});
            
            chart.timeScale().fitContent();

            chartContainerRef.current.style.position = 'relative';

            const areaSeries = chart.addAreaSeries({
                topColor: 'rgba(247, 89, 171, 0.3)',
                bottomColor: 'rgba(82, 3, 57, 0.25)',
                lineColor: 'rgba(247, 89, 171, 1)',
                lineWidth: 3,
                priceLineWidth: 2,
                crossHairMarkerVisible: false,
            });

            areaSeries.setData(data);

            areaSeries.applyOptions({
                priceFormat: {
                    type: 'custom',
                    minMove: '0.00000001',
                    formatter: (price) => {
                        if (price <= 0) return parseFloat(price).toFixed(0)
                        if (price < 0.001) return parseFloat(price).toFixed(8)
                        else if (price >= 0.001 && price < 0.1) return parseFloat(price).toFixed(6)
                        else if (price >= 0.1 && price < 1) return parseFloat(price).toFixed(4)
                        else return parseFloat(price).toFixed(2)
                    }
                }
            });

            chart.subscribeCrosshairMove((param) => {
                if (param.time && param.point !== undefined) {
                    const price = param.seriesData.get(areaSeries);
                    firstRow.innerText = coinName + ' - ' + formatPrice(price.value);
                }
                else {
                    firstRow.innerText = coinName;
                }
            });

            const legend = document.createElement('div');
            legend.classList.add('legend');
            chartContainerRef.current.appendChild(legend);

            const firstRow = document.createElement('div');
            firstRow.innerText = coinName;
            firstRow.style.color = 'white';
            legend.appendChild(firstRow);
    
            function pad(n) {
                var s = ('0' + n);
                return s.substr(s?.length - 2);
            }

			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[props, colors]
	);

    if (!colors.darkPrimary) return <Loader/>;

	return (
        <div
			ref={chartContainerRef}
		/>
	);
};

export default memo(LightWeightChart)