import React from "react";
import { Link } from 'react-router-dom';
import { Menu } from "antd";
import {HomeOutlined, LineChartOutlined, ToolOutlined, TableOutlined, ThunderboltOutlined, QuestionCircleOutlined, StockOutlined, ControlOutlined} from "@ant-design/icons";

const LeftMenu = ({ mode }) => {
  const menuItems = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: <Link to="/">Home</Link>,
    },
    {
      key: 'cryptocurrencies',
      icon: <LineChartOutlined />,
      label: <Link to="/cryptolist">Cryptocurrencies</Link>,
    },
    {
      key: 'treemap',
      icon: <TableOutlined />,
      label: <Link to="/treemap">Treemap</Link>,
    },
    {
      key: 'topcoins',
      icon: <ThunderboltOutlined />,
      label: <Link to="/topCoins">Top Coins</Link>,
    },
    {
      key: 'tools',
      icon: <ToolOutlined />,
      label: 'Tools',
      children: [
        {
          key: 'cryptoindex',
          icon: <ControlOutlined />,
          label: <Link to="/cryptoindex">Index</Link>
        },
        {
          key: 'whatif',
          icon: <QuestionCircleOutlined />,
          label: <Link to="/whatif">What If</Link>
        },
        {
          key: 'compare',
          icon: <StockOutlined />,
          label: <Link to="/compare">Compare</Link>
        },
      ],
    }
  ]
  return (
    <Menu items={menuItems} mode={mode}/>
  );
};

export default LeftMenu;