import { SET_THEME, SET_COLORS } from '../constants/actionTypes';
import * as api from '../api';

export const setTheme = (theme) => async (dispatch) => {
    try {
        //
        dispatch({ type: SET_THEME, payload: theme});
        //await api.setTheme({theme}) //écriture BDD ? route pas fait encore je crois ? rajouter get 
    } catch (error) {
        console.log(error.message)
    }
}

export const setColors = (colors) => async (dispatch) => {
    try {
        dispatch({ type: SET_COLORS, payload: colors});
    } catch (error) {
        console.log(error.message)
    }
}