import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Select} from 'antd';

import { setCurrency } from '../../actions/setCurrency';
import store from '../../app/Store'

const Currency = () => {
  const currency = useSelector((state) => state.reducers.setCurrency)
  const dispatch = useDispatch()

// il faut travailler sur la valeur par défaut, 
  return (
    <Select
      defaultValue={currency.label}
      style={{ width: 90 }}
      onChange={(currency) => {dispatch(setCurrency({value : currency}))}}
      options={[
        { value: 'HIVsRcGKkPFtW', label: 'USDT'},
        { value: 'Qwsogvtv82FCd', label: 'BTC'},
        { value: 'vVQDfP0z1', label: 'EUR' },
    ]}
    />
  )
}

export default Currency