import React, {useState, useEffect, useRef, useLayoutEffect, memo} from 'react'
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Ticker from 'react-ticker'
import { Typography } from 'antd';

import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'

import { useGetListQuery } from '../../services/cryptoQuery'
import {useHover} from './useHover';

const { Title, Text } = Typography;

const TickerTape = () => {
    const [ref, hovering] = useHover();
    const currency = useSelector((state) => state.reducers.setCurrency.value)
    const count = 100
    const offset = 0
    const { data: cryptosList, isFetching } = useGetListQuery({count, offset, currency});
    const [seed, setSeed] = useState(1);
    const reset = () => {
         setSeed(Math.random());
    }

    //même si la liste se met à jour le tickertape ne se met pas à jour si la page ne se recharge pas
    //essayer de maper à lextérieur du return avec push : marche pas;
    //peut être mettre un location qui mémorise lurl puis un navigate quand on change 
    //le ticker tape peut recevoir du flux continu mais une fois qu'un truc rentre dedans il est considéré immuable, sauf actualisation de la page    
    //ou alors enlever l'impact de la currency sur le tickertape

    useEffect(() => {
        reset()
    }, [cryptosList]);

    return (
    <div ref={ref}>
        <Ticker className='ticker-tape' move={!hovering} height="30" offset='run-in' mode='chain' key={seed}>    
            {({index}) => (
                <>
                    {cryptosList && cryptosList?.data?.coins?.map((coin, i) => (     
                        <span className='ticker-item' key={i}>
                            <Link to={`/CoinPage/${coin.uuid}`}>
                                <img width="20" height="20" className='ticket-tape-logo' src={coin?.iconUrl}></img>
                                <Text strong="true"> {coin?.symbol} : </Text>
                                {coin?.change >= 0 ? <CaretUpFilled style={{ color: '#00FF00' }}/> : <CaretDownFilled style={{ color: '#FF0000' }}/>}
                                <Text> {Number(coin?.price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} </Text>
                                <Text>{coin?.change <= 0 ? " (" : "(+"}</Text>
                                <Text>{coin?.change}%) </Text>
                                </Link>
                        </span>
                    ))}
                </>
            )}
        </Ticker>
    </div>
        
    )
}

export default TickerTape