import React, {memo} from 'react';
import ReactSpeedometer from "react-d3-speedometer"
import {FibonacciRetracement} from './technicalIndicators';

const Speedometer = (data) => {
    const fibo = FibonacciRetracement({history : data.data});

    console.log()

    return (
        <ReactSpeedometer
        forceRender={true}
        width={document.body.clientWidth < 575 ? 200 : 250} height={150} ringWidth={50} needleHeightRatio={0.6} pointerWidth={18} pointerTailLength={5}
        labelFontSize='10px' valueTextFontSize='12px' paddingHorizontal={10} paddingVertical={10}
        needleTransitionDuration={2000} needleTransition="easeCubicOut"
        needleColor={'#262626'} textColor={'#c41d7f'}
        segmentColors={['#95de64', '#b7eb8f', '#d9f7be', '#fafafa', '#ffd6e7', '#ffadd2', '#ff7875']}
        minValue={Number(fibo?.minPrice.toFixed(4))} maxValue={Number(fibo?.maxPrice.toFixed(4))}
        customSegmentStops={[Number(fibo?.minPrice.toFixed(4)), Number(fibo?.fr1.toFixed(4)), Number(fibo?.fr2.toFixed(4)), Number(fibo?.fr3.toFixed(4)), Number(fibo?.fr5.toFixed(4)), Number(fibo?.fr6.toFixed(4)), Number(fibo?.fr7.toFixed(4)), Number(fibo?.maxPrice.toFixed(4))]}
        value={Number(fibo?.lastPrice.toFixed(2))}
        currentValueText="Current Price : #{value}" currentValuePlaceholderStyle={'#{value}'}
        valueFormat=","
        customSegmentLabels={[
            {
            position: "OUTSIDE",
            color: "#555",
            },
            {
            text: `< ${fibo?.fr2.toFixed(2)}`,
            position: "OUTSIDE",
            color: "#555",
            },
            {
            text: `< ${fibo?.fr3.toFixed(2)}`,
            position: "OUTSIDE",
            color: "#555",
            },
            {
            text: "Wait Zone",
            position: "OUTSIDE",
            color: "#555",
            },
            {
            text: `> ${fibo?.fr5.toFixed(2)}`,
            position: "OUTSIDE",
            color: "#555",
            },
            {
            text: `> ${fibo?.fr6.toFixed(2)}`,
            position: "OUTSIDE",
            color: "#555",
            },
            {
            position: "OUTSIDE",
            color: "#555",
            }
            ]}
        />
    )   
}

export default memo(Speedometer)