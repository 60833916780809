import { FETCH_ALLTIMEHISTORY, ALLTIMEHISTORY_START_LOADING, ALLTIMEHISTORY_END_LOADING} from '../constants/actionTypes';
import * as api from '../api';

export const getAllTimeHistory = (name) => async (dispatch) => {
    try {
        dispatch({ type: ALLTIMEHISTORY_START_LOADING });
        const { data } = await api.fetchAllTimeHistory(name);
        dispatch({ type: FETCH_ALLTIMEHISTORY, payload: data});
        dispatch({ type: ALLTIMEHISTORY_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}