import { FETCH_PORTFOLIO, CREATE_PORTFOLIO_LINE, DELETE_PORTFOLIO_LINE, LIKE_PORTFOLIO_LINE, PORTFOLIO_START_LOADING, PORTFOLIO_END_LOADING } from '../constants/actionTypes';

const portfolio = (state = { ptfLoading: true, portfolio: [] }, action) => {
    switch (action.type) {
        case PORTFOLIO_START_LOADING:
            return { ...state, ptfLoading: true };
        case PORTFOLIO_END_LOADING:
            return { ...state, ptfLoading: false };
        case FETCH_PORTFOLIO:
            return { ...state, portfolio : action.payload };
        case LIKE_PORTFOLIO_LINE:
            return { ...state, portfolio : action.payload };
        case CREATE_PORTFOLIO_LINE:
            return { ...state, portfolio : action.payload };
        case DELETE_PORTFOLIO_LINE: //faut voir comment faire
            return { ...state, portfolio : action.payload };
            //return state.portfolio.filter((portfolioLine) => portfolioLine.uuid !== action.payload); //trouver ocmment considérer portfolio a l'intérieur du state et non la fonction
        default:
            return state;
    }
};

export default portfolio;