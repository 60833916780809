import React, { useState, useEffect, useMountEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import EditableTable from './EditableTable'
import { getLocalCoins } from '../../actions/localCoins' //a remplacer par coinslist
import { getPortfolio, deletePortfolioLine } from '../../actions/portfolio';
import { useGetUuidListQuery} from '../../services/cryptoQuery';
import SearchCoin from "./SearchCoin";
import store from '../../app/Store'


import Loader from './../Loader';

const Portfolio = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const id = user?.result?.sub
  let localCoinsDataSource = [];

  console.log(store.getState())

  useEffect(() => {
    dispatch(getLocalCoins(JSON.stringify({fav : id})));
  }, [dispatch])

  useEffect(() => {
    dispatch(getPortfolio(user))
  }, [dispatch])

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  
  const { localCoins, isLoading } = useSelector((state) => state.reducers.localCoins);
  const { portfolio, ptfLoading } = useSelector((state) => state.reducers.portfolio);

  const localCoinsUuids = localCoins?.map(({ uuid }) => {
    return(uuid) })

  const isLongEnough = localCoinsUuids.length > 0

  console.log(isLongEnough)
  
  
  const { data : localCoinsData, isFetching : localCoinsDataFetching } =  useGetUuidListQuery(localCoinsUuids, {skip: !isLongEnough}); //première requête dans le vide 404 parce que pas chargement encore de favcoinsuuid

  if(!user?.result?.name) {
    return(
      "Please Sign In to create your own portfolio"
    )
  }

  if (isLoading || ptfLoading || localCoinsDataFetching) return <Loader />;

  if (localCoins.length > 0) {
    localCoins?.forEach(element => {
      localCoinsDataSource.push({
        key: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].rank,
        rank: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].rank,
        uuid: element.uuid,
        icon: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].iconUrl,
        symbol: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].symbol,
        name: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].name,
        price: localCoinsData?.data?.coins?.filter(coin => coin.uuid == element.uuid)[0].price,
        quantity : portfolio.length > 0 ? portfolio?.find(line => line.uuid == element.uuid) ? portfolio?.find(line => line.uuid == element.uuid).quantity : 0 : 0,
        buyPrice : portfolio.length > 0 ? portfolio?.find(line => line.uuid == element.uuid) ? portfolio?.find(line => line.uuid == element.uuid).price : 0 : 0,
        total : portfolio.length > 0 ? portfolio?.find(line => line.uuid == element.uuid) ? portfolio?.find(line => line.uuid == element.uuid).total : 0 : 0,
      });
    })
  }



  return (
    <>
      <Row className='search-portfolio-container'>
        <Col xl={24} xs={24} className='search-portfolio'>
          <SearchCoin/>
        </Col>
      </Row>
      <Row>
        <Col xl={24} xs={24}>
          <EditableTable className='portfolio' localCoinsDataSource = {localCoinsDataSource}/>
        </Col>
      </Row>
    </>
  )
}

export default Portfolio