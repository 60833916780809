import { combineReducers } from 'redux';
import auth from './auth';
import localCoins from './localCoins';
import webSockets from './webSockets';
import portfolio from './portfolio';
import addPortfolioLine from './addPortfolioLine';
import {setTheme} from './setTheme';
import {setColors} from './setTheme';
import {setCurrency} from './setCurrency';
import lunarCoins from './lunarCoins';
import allTimeHistory from './allTimeHistory';

export const reducers = combineReducers({
    auth,
    localCoins,
    webSockets,
    portfolio,
    addPortfolioLine,
    setTheme,
    setColors,
    lunarCoins,
    setCurrency,
    allTimeHistory
});