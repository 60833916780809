import { LUNARWEEK_V2_START_LOADING, FETCH_LUNAR_COIN_WEEK_V2, LUNARWEEK_V2_END_LOADING, LUNARYEAR_V2_START_LOADING, FETCH_LUNAR_COIN_YEAR_V2, LUNARYEAR_V2_END_LOADING, LUNAR_LIST_START_LOADING, FETCH_LUNAR_COINS_LIST, LUNAR_LIST_END_LOADING, LUNAR_LIST_AGG_START_LOADING, FETCH_LUNAR_COINS_LIST_AGG, LUNAR_LIST_AGG_END_LOADING, LUNAR_TOPCOINS_START_LOADING, FETCH_LUNAR_TOPCOINS, LUNAR_TOPCOINS_END_LOADING } from '../constants/actionTypes';
import * as api from '../api';

export const getLunarCoinsListAgg = (name) => async (dispatch) => {
    try {
        dispatch({ type: LUNAR_LIST_AGG_START_LOADING });
        const { data } = await api.fetchLunarCoinsListAgg(name);
        dispatch({ type: FETCH_LUNAR_COINS_LIST_AGG, payload: data});
        dispatch({ type: LUNAR_LIST_AGG_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const getLunarCoinsList = (name) => async (dispatch) => {
    try {
        dispatch({ type: LUNAR_LIST_START_LOADING });
        const { data } = await api.fetchLunarCoinsList(name);
        dispatch({ type: FETCH_LUNAR_COINS_LIST, payload: data});
        dispatch({ type: LUNAR_LIST_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const getLunarTopCoins = (limit, offset) => async (dispatch) => {
    try {
        dispatch({ type: LUNAR_TOPCOINS_START_LOADING });
        const { data } = await api.fetchLunarTopCoins(limit, offset);
        dispatch({ type: FETCH_LUNAR_TOPCOINS, payload: data});
        dispatch({ type: LUNAR_TOPCOINS_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const getLunarCoinWeekV2 = (name) => async (dispatch) => {
    try {
        dispatch({ type: LUNARWEEK_V2_START_LOADING });
        const { data } = await api.fetchLunarCoinWeekV2(name);
        dispatch({ type: FETCH_LUNAR_COIN_WEEK_V2, payload: data});
        dispatch({ type: LUNARWEEK_V2_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const getLunarCoinYearV2 = (name) => async (dispatch) => {
    try {
        dispatch({ type: LUNARYEAR_V2_START_LOADING });
        const { data } = await api.fetchLunarCoinYearV2(name);
        dispatch({ type: FETCH_LUNAR_COIN_YEAR_V2, payload: data});
        dispatch({ type: LUNARYEAR_V2_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}