import { ADD_PORTFOLIO_LINE, FETCH_PORTFOLIO, CREATE_PORTFOLIO_LINE, UPDATE_PORTFOLIO_LINE, DELETE_PORTFOLIO_LINE, LIKE_PORTFOLIO_LINE, PORTFOLIO_START_LOADING, PORTFOLIO_END_LOADING } from '../constants/actionTypes';
import * as api from '../api';

export const getPortfolio = (userId) => async (dispatch) => {
    try {
        dispatch({ type: PORTFOLIO_START_LOADING });
        const { data } = await api.fetchPortfolio(userId);
        dispatch({ type: FETCH_PORTFOLIO, payload: data});
        dispatch({ type: PORTFOLIO_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const addPortfolioLine = (line) => async (dispatch) => {
    try {
        dispatch({ type: ADD_PORTFOLIO_LINE, payload: line});
    } catch (error) {
        console.log(error.message)
    }
}

export const createPortfolioLine = (line) => async (dispatch) => {
    try {
        const { data } = await api.createPortfolioLine(line);

        dispatch({ type: CREATE_PORTFOLIO_LINE, payload: data })
    } catch (error) {
        console.log(error);
    }
}

export const deletePortfolioLine = (uuid) => async (dispatch) => {
    try {
        const { data } = await api.deletePortfolioLine(uuid);
        dispatch({ type : DELETE_PORTFOLIO_LINE, payload : data });
    } catch (error) {
        console.log(error);
    }
}


export const likePortfolioLine = (id, mode) => async (dispatch) => {
    try {
        const { data } = await api.likeLocalCoin(id, mode);
        dispatch({ type : LIKE_PORTFOLIO_LINE, payload: data});
    } catch (error) {
        console.log(error);
    }
}