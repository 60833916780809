import React from 'react'
import { Row, Col, Input } from 'antd'

const CustomInput = ({ id, placeholder, handleChange, type, half }) => {
  return (
      <Col xs={24} sm={half ? 12 : 24}>
        <div className="auth-input-control">
         {id == 'password' || id == 'confirmPassword' ? 
            <Input.Password
              id={id}
              placeholder={placeholder}
              onChange={handleChange}
              size="large"
            /> :
            <Input 
              id={id}
              placeholder={placeholder}
              onChange={handleChange}
              size="large"
              type={type}
            />
          }
        </div>
      </Col>
  )
}

export default CustomInput