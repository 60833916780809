import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

Chart.register(...registerables);
Chart.register(zoomPlugin);

const SocialChart = (historyData) => {

    const colors = useSelector((state) => state.reducers.setColors);

    const activePosts = [...historyData.activePosts];
    const newPosts = [...historyData.newPosts];
    const interactions = [...historyData.interactions];
    const price = [...historyData.price];
    //const tweetIndex = [...historyData.indexData];
    const showPrice = historyData.showPrice;
    const socialChartSwitch = historyData.socialChartSwitch;
    const activePostsChart = activePosts.map(d => d.value);
    const newPostsChart = newPosts.map(d => d.value);
    const interactionsChart = interactions.map(d => d.value);
    const priceChart = price.map(d => d.value);
    //const coinIndex = tweetIndex.map(d => d[1]);
   //const coinLines = [...coinTweets, ...coinIndex]
    const postsTimeStamp = activePosts.map(d => new Date(d.time * 1000));
  
    //const max = Math.max(...coinTweets, ...coinLines)
    const max = parseInt(Math.max(...activePostsChart, ...newPostsChart) * 1.2)
    const maxInteractions = parseInt(Math.max(...interactionsChart) * 1.2)

    const dataSet = [
        {
          label:'Close Price',
          data: priceChart,
          fill: true,
          borderColor : '#EE0000',
          pointRadius: 0.5,
          borderWidth: 0.5,
          spanGaps: true,
          tension: 0.1,
          yAxisID: 'y1',
        },
        {
          label:'New Posts',
          data: newPostsChart,
          fill: true,
          borderColor : '#FFADD2',
          backgroundColor : '#c41d7f',
          type: 'bar',
          yAxisID: 'y',
        },
        {
          label:'Active Posts',
          data: activePostsChart,
          fill: true,
          borderColor : '#00acee',
          backgroundColor: '#ADD8E6',
          pointRadius: 1,
          borderWidth: 1,
          spanGaps: true,
          tension: 0.2,
          yAxisID: 'y',
        },
        {
          label:'Interactions',
          data: interactionsChart,
          fill: true,
          borderColor : '#c41d7f',
          pointRadius: 1,
          borderWidth:1,
          spanGaps: true,
          tension: 0.2,
          yAxisID: 'y',
        },
      ]

      const filtredDataSet = []
      socialChartSwitch == 'posts' ? 
      dataSet?.forEach(function (e) {
        if (!showPrice ? e.label === 'Active Posts' || e.label === 'New Posts' : e.label === e.label === 'Close Price' || 'Active Posts' || e.label === 'New Posts') {
          filtredDataSet.push(e)
        }
      })
      :
      dataSet?.forEach(function (e) {
        if (!showPrice ? e.label === 'Interactions' : e.label === 'Close Price' || e.label === 'Interactions') {
          filtredDataSet.push(e)
        }
      })

      const data = {labels : postsTimeStamp, datasets : filtredDataSet}
      
      /*
      {
        labels : postsTimeStamp, 
        datasets : 
          [dataSets.find(e => e.label === 'Interactions')]
          :
          [dataSets.find(function (e) { 
            if ( e.label === 'Active Posts') {console.log(e)}
            //{ data_jouj.push(e)}
          })]
      }
      */

    /*
    const data2 = {
      labels: coinTimeStamp,
      datasets: [{
          label:'tweets',
          data: coinTweets,
          fill: true,
          borderColor : '#c41d7f',
          pointRadius: 1,
          borderWidth:2,
          spanGaps: true,
          tension: 0.2
      },
      {
        label:'index',
        data: coinIndex,
        fill: true,
        borderColor : '#ffffff',
        pointRadius: 1,
        borderWidth:2,
        spanGaps: true,
        tension: 0.2
      }
    ]    
  }*/

    const options ={
        //aspectRatio:1,
        maintainAspectRatio: false,
        responsive: true, 
        plugins: {
          legend: {
            display: false,
            labels: {
              display: false,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
            },
            pan: {
              enabled: true,
            },
            limits: {
              x: {min: 'original', max: 'original'},
              y: {min: 'original', max: 'original'}
            },
          },
          tooltips: {
            callbacks: {
              title: function (tooltip) {
                return "";
              }
            }
          },      
          title: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              parser: 'YYYY-MM-DD HH:mm:ss ZZZZ',
              tooltipFormat: 'll HH:mm',
              unit: 'hour',
              unitStepSize: 1,
              displayFormats: {
                'day': 'MM/DD/YYYY'
              }
            }
          },
          y: {
            grid: {
              display:false
            },
            ticks: { color: colors.darkPrimary, beginAtZero: true },
            display: true,
            position: 'left',
            max: socialChartSwitch == 'interactions' ? maxInteractions : max,
          },
          y1: {
            type: 'linear',
            ticks: { color: colors.darkPrimary, beginAtZero: true },
            display: showPrice ? true : false,
            position: 'right',
            grid: {
              display:false
            },
          },
        },
      }

    return (
        <>
          <div style={{height:'100%'}}>
              <Line data={data} options={options} />
          </div>
        </>
    )
}

export default SocialChart
