import React from 'react';
import {useNavigate} from 'react-router-dom';
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Table, Avatar, Pagination, Switch, Card, Segmented, Space, Typography} from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import {NumericFormat} from 'react-number-format';
import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesReferenceLine } from 'react-sparklines';

import { useGetListQuery } from '../services/cryptoQuery';
import {WebSockets} from './WebSocket/webSockets'

import store from '../app/Store'

var wsPrices = []

const Cryptolist = ({simplified}) => {
  const navigate = useNavigate ();
  const currency = useSelector((state) => state.reducers.setCurrency.value)
  const [listType, setListType] = useState(false)
  const [timePeriod, setTimePeriod] = useState('1h')
  const periods = ['1h', '3h', '12h', '24h', '7d', '30d', '3m', '1y', '3y', '5y'];
  const cryptoTableDataSource = []
  const [page, setPage] = useState(1)
  const [count, setLimit] = useState(simplified ? 10: 100)
  const [offset, setOffset] = useState(0)
  const { data: cryptosList, isFetching } = useGetListQuery({count, offset, timePeriod, currency});
  const max = cryptosList?.data?.stats?.total
  const redLight = getComputedStyle(document.documentElement).getPropertyValue('--redDownLight')
  const greenLight = getComputedStyle(document.documentElement).getPropertyValue('--greenUpLight')
  const red = getComputedStyle(document.documentElement).getPropertyValue('--redDown')
  const green = getComputedStyle(document.documentElement).getPropertyValue('--greenUp')
  const [refresh, setRefresh] = useState(0);

  const wsx = WebSockets()

  const webSocketPrices = () => {
    const storeData = store.getState()
    const history = storeData?.reducers?.webSockets?.history
    wsPrices = history
  }

  useEffect(() => {
    var run = 0
    const interval = setInterval(() => {
      run += 1
      if (refresh < 30){ //mettre plutot idle
        setRefresh(refresh => refresh  + 1);
        webSocketPrices()
      }
      else if (refresh >= 30){
        clearInterval(interval);
      }
      }, 10000);
      return () => clearInterval(interval);
  }, [refresh]);

  cryptosList?.data?.coins?.forEach(element => {
    cryptoTableDataSource.push({
    key : element.uuid,
    rank : element.rank,    
    icon : element.iconUrl,
    symbol : element.symbol,
    name : <>{element.name + " "} {element.change >= 0 ? <CaretUpFilled style={{ color: green }}/> : <CaretDownFilled style={{ color: red }}/>}</>,
    price : wsPrices[element.uuid]?.price? wsPrices[element.uuid]?.price : element.price,
    change : element.change,
    volume : element['24hVolume'],
    marketCap : element.marketCap,
    sparkline : element.sparkline
    })
  })

  const cryptoTableColumns = [
    {title: '°', dataIndex: 'rank', key: 'rank', align:'center', width:30},
    {title: '', dataIndex: 'icon', key: 'icon', align:'center', render:icon => (<Avatar src={icon}/>), width : 30, fixed: 'left'},
    {title: 'Name', dataIndex: 'name', key: 'name', align:'center', width:150},
    {title: '$', dataIndex: 'symbol', key: 'symbol',align:'center', width:50, fixed: 'left'},
    {title: 'Price', dataIndex: 'price', key: 'price', align:'center', render:price => ( <NumericFormat value={price} displayType={'text'} thousandSeparator={true} decimalScale={2}/>), width : 100},
    {title: 'Change', dataIndex: 'change', key: 'change' ,align:'center', render:change => ( <NumericFormat value={change} displayType={'text'} suffix={'%'} thousandSeparator={false} decimalScale={2}/>), width : 100},
    {title: 'volume', dataIndex: 'volume', key: 'volume', align:'center', render:volume => ( <NumericFormat value={volume} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), width : 100},
    {title: 'Market Cap', dataIndex: 'marketCap', key: 'marketCap', align:'center', align:'center', render:marketCap => ( <NumericFormat value={marketCap} displayType={'text'} thousandSeparator={true} decimalScale={0}/>), width : 150},
    {title: 'Sparkline', dataIndex: 'sparkline', key: 'sparkline', align:'center', render:sparkline => ( <Sparklines data={sparkline} margin={0} height={25}><SparklinesLine style={{ stroke: "#41c3f9", fill: "lightblue" }} /></Sparklines>), width : 300},
  ]

  return (
    <>
      {!simplified && (
        <Row className='list-options-container'>
          <Space className='list-options'>
            <Col>
              <Switch size="default" checked={listType} onChange={setListType} checkedChildren="cards" unCheckedChildren="cards"/>
            </Col>
            <Col>
              <Segmented
                size="small"
                className="top-coins-periods"
                id='data-selector'
                value={timePeriod}
                options={periods}
                onChange={setTimePeriod}
              />
            </Col>
          </Space>
        </Row>

      )}
      
      <Row>

        {!listType? (

          <Col xl={24} xs={24} className= {simplified ? 'crypto-list-simplified' : 'crypto-list'}>
            <Table dataSource={cryptoTableDataSource} columns={cryptoTableColumns} scroll={{ x: '1500px' }} pagination={false} onRow={(record, rowIndex) => { return {onClick: event => navigate(`/coinpage/${record.key}`)}}}style={{cursor: "pointer"}}/>
          </Col>

          ):

          <Row gutter={[32, 32]} className="crypto-card-container">
            {cryptoTableDataSource?.map((currency) => (
              <Col xs={24} sm={12} lg={6} className="crypto-card" key={currency.id}>
                <Link to={`/coinpage/${currency.uuid}`}>
                  <Card
                    className= {currency.change >=0 ? 'crypto-card-up' : 'crypto-card-down'} 
                    //headStyle= {currency.change >= 0 ? { backgroundColor: redLight } : {backgroundColor: greenLight}} //c'est moche, changer, rajouter la fleche
                    title={<Typography className={currency.change >=0 ? 'crypto-card-title-up' : 'crypto-card-title-down'}>{`
                      ${currency.change >= 0 ? '▲' : '▼'}
                      ${currency.rank}
                      ${currency.symbol}
                      $${parseFloat(currency.price).toFixed(2)}
                      (${parseFloat(currency.change)}%)
                    `}</Typography>}
                    extra={<img className="crypto-image" src={currency.iconUrl} />}
                    hoverable
                  >                              
                  <Sparklines data={currency.sparkline}>
                    <SparklinesLine style={{ stroke: "#41c3f9", fill: "lightblue" }} />
                    <SparklinesSpots />
                    <SparklinesReferenceLine type="mean" />
                    </Sparklines>  
                  </Card>               
                </Link>
              </Col>
            ))}
          </Row>
        }


        {!simplified && (
          <Col xl={24} xs={24} className='pagination-container'>
            <Pagination className='pagination' defaultCurrent={page} current={page} defaultPageSize={count} total={max} onChange={(current, limit)=>{
              setPage(current);
              setLimit(limit);
              setOffset(current*limit-limit);
            }
            }/>
          </Col>
        )}

        {simplified && (
          <Col xl={24} xs={24} className='home-coins-list-more'>
            <Link to="/cryptolist">See More</Link>
          </Col>
        )}  

      </Row>
    </>
  )
}

export default Cryptolist