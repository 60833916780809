import { AUTH, GOOGLEUSER } from '../constants/actionTypes';
import * as api from '../api';

export const signin = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data})

        navigate('/')
    } catch (error) {
        console.log(error)
    }

}

export const signup = (formData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signUp(formData);

        dispatch({ type: AUTH, data})

        navigate('/')
    } catch (error) {
        console.log(error)
    }

}

export const googleUser = (result, navigate) => async (dispatch) => {
    try {
        const { data } = await api.googleUser(result);
        console.log(result)
        dispatch({ type: GOOGLEUSER, data})

        navigate('/')
    } catch (error) {
        console.log(error)
    }

}