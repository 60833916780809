import { SET_THEME, SET_COLORS } from '../constants/actionTypes';

export const setTheme = (setTheme = [], action) => {
    switch (action.type) {
        case SET_THEME:
            return action.payload;
        default:
            return setTheme;
    }
};

export const setColors = (setColors = [], action) => {
    switch (action.type) {
        case SET_COLORS:
            return action.payload;
        default:
            return setColors;
    }
};//rajouter start loading & end loading ?

