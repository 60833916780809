import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate , useLocation} from 'react-router-dom';
import axios from 'axios';
import { Form, AutoComplete } from 'antd';
import {fetchSuggestions} from '../../services/fetchSuggestions';
import { addPortfolioLine } from '../../actions/portfolio';

//dossier auto-complete/style rajouté manuellement dans node_modules car requis par craco (craco utilisé pour less) ?

const {Option} = AutoComplete;

const SearchCoin = () => {
  const [suggestions, setSuggestions] = useState();
  const navigate = useNavigate ();
  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchSuggestions = async (query) => {
    if (query && query.length >= 2) {
      const {data} = await axios.get(fetchSuggestions(query))
      return {data}
    }
  };

  const selectSuggestions = async (value) => {
      const filter = suggestions.filter((line) => line.uuid == value)
      dispatch(addPortfolioLine(filter[0]))
      form.resetFields(["autoComplete"]);
  }

  return (

    <Form form={form} >
      <Form.Item name="autoComplete">
        <AutoComplete
          id="autoComplete"
          placeholder="Add to Portfolio"
          style={{
            width: 200,
          }}
          onChange={((query) => searchSuggestions(query)
          .then((result) => setSuggestions(result?.data?.data?.coins))
          )}
          onSelect={((value) => selectSuggestions(value))}
          allowClear={true}
        >
          {suggestions?.map((crypto) => (
            <Option
            id="options"
            key={crypto.uuid}
            value= {crypto.uuid}
            >
              {crypto.name}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
    </Form>
  )
};

export default SearchCoin;