import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Layout, Button, Drawer, Switch } from "antd";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import SearchMenu from "./SearchMenu";
import Currency from "./Currency";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { setTheme } from '../../actions/setTheme';

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };
  const [switchTheme, setSwitchTheme] = useState(JSON.parse(localStorage.getItem('theme')) === null ? true : JSON.parse(localStorage.getItem('theme')))
  const dispatch = useDispatch()
  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  let { pathname: location } = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);
  // Upto here
  var user = JSON.parse(localStorage.getItem('profile'))

  useEffect(() => {
    dispatch(setTheme(switchTheme));
    localStorage.setItem('theme', switchTheme);
  }, [switchTheme, location], dispatch);

  return (
    <nav className="navbar">
      <Layout>
        <Layout.Header className="nav-header">
          <Link to={'/'}>
            <div className="logo">
              <h3 className="brand-font">SoCoins.io</h3>
            </div>
          </Link>
          <div className="navbar-menu">
            <div className="leftMenu">
              <LeftMenu mode={"horizontal"} />
            </div>
            <Button className="menuButton" type="text" onClick={showDrawer}>
              <MenuOutlined />
            </Button>
            <div className="rightMenu">
              <RightMenu mode={"horizontal"} />
            </div>
            {!user ? (
              <div className="signin-button-div">
                <Button className="signin-button" type="primary" size="small" href="/auth">SIGN IN</Button>
              </div>
            ) : (<></>)}
            <div className="switch-theme">
              <Switch id="switch-theme-button" checkedChildren="dark" unCheckedChildren="light" checked={switchTheme} size="small" onChange={setSwitchTheme}/>
            </div>
            <div className="currency">
              <Currency />
            </div>
            <div className="searchMenu">
              <SearchMenu />
            </div>



            <Drawer
              title={"SoCoins"}
              placement="right"
              closable={true}
              onClose={showDrawer}
              open={visible}
              style={{ zIndex: 99999 }}
            >
              <div className="searchMenu-mobile"><SearchMenu /></div>
              <LeftMenu mode={"inline"} />
              <RightMenu mode={"inline"} />
            </Drawer>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

export default Navbar;