import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = 'https://socoins-d3843d3c894b.herokuapp.com/coins';

const createRequest = (url) => ({url,headers: []});

export const cryptoQuery = createApi({
    reducerPath: 'cryptoQuery',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getList: builder.query({
            query: ({ count, offset, timePeriod, currency }) => createRequest(`/list/${count}/${offset}/${timePeriod? timePeriod : '24h'}/${currency? currency : 'HIVsRcGKkPFtW' }`),
        }),
        getExchanges: builder.query({
            query: () => createRequest(`/exchanges`),
        }),
        getDetails: builder.query({
            query: ( {coinId, currency} ) => createRequest(`/details/${coinId}/${currency}`),
        }),
        getExchangesDetails: builder.query({
            query: ( coinId ) => createRequest(`/coinexchanges/${coinId}`),
        }),
        getHistory: builder.query({
            query: ({ coinId, timePeriod, currency }) => createRequest(`/history/${coinId}/${currency}?timePeriod=${timePeriod}`),
        }),
        getSearch: builder.query({
            query: ( query ) => createRequest(`/search/${query}`),
        }),
        getOhlc: builder.query({
            query: ({ coinId, ohlcInterval, currency }) => createRequest(`/ohlc/${coinId}/${currency}?interval=${ohlcInterval}`),
        }),
        getUuidList: builder.query({
            query: ( uuid ) => createRequest(`/uuidlist/${uuid}`),
        }),
        getStats : builder.query({
            query: (currency) => createRequest(`/stats/${currency}`),
        }),
        getMarketCapHistory : builder.query({
            query: (coinId) => createRequest(`/coin/${coinId}/market-caps`),
        }),
    }),
});

export const { useGetListQuery, useGetExchangesQuery, useGetExchangesDetailsQuery, useGetDetailsQuery, useGetHistoryQuery, useGetSearchQuery, useGetOhlcQuery, useGetUuidListQuery, useGetStatsQuery, useGetMarketCapHistoryQuery} = cryptoQuery;

//depuis que j'essaie de rajouter la currency dans cryptopage ça bug