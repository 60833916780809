import React, {memo} from 'react';
import {NumericFormat} from 'react-number-format';

const SMA = require('technicalindicators').SMA;
const EMA = require('technicalindicators').EMA;

//merttre le prix/map dans une fonction async avec promise all

export const MovingAverage = (params) => {
    if (params === null || params === undefined) {
        console.log("Fetching ...")
    }

    else {
        const value = params?.data?.map(function (item) {
            return item.value
        })

        const smaTable = params?.data?.map(function (item) {
            return {time : item.time}
        })
        const emaTable = params?.data?.map(function (item) {
            return {time : item.time}
        })

        if (params.exponential === false) {
            const sma = SMA.calculate({period : params?.period, values : value })
            const lsma = sma[sma.length -1]
            const delta = smaTable.length - sma.length
            for(let i = 0; i < sma.length; i++){
                smaTable[delta + i].value = sma[i]
            }
            
            if (params.history === false) {
                return <NumericFormat value={lsma} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2"/>
            } else if (params.history === true) {
                return smaTable
            }

        } else if (params.exponential === true) {
            const ema = EMA.calculate({period : params?.period, values : value })
            const lema = ema[ema.length -1]
            const delta = emaTable.length - ema.length
            for(let i = 0; i < ema.length; i++){
                emaTable[delta + i].value = ema[i]
            }

            if (params.history === false) {
                return <NumericFormat value={lema} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale="2"/>
            } else if (params.history === true) {
                return emaTable
            }
        }
    }

    return (
        <>{MovingAverage()}</>
    )  

};


export const FibonacciRetracement = (data) => {
    
    if (!data) {
        console.log("Fetching ...")
    } else {
        const fibo = [0, 0.236, 0.382, 0.50, 0.618, 0.786, 1]
        const value = data?.history.map(function (item) {
            return item.value
        })
        const max = Math.max(...value)
        const min = Math.min(...value)
        const first = value[0]
        const last = value[value.length - 1]
 
        if (last - first > 0) {
            const fur1 = max - ((max-min)*fibo[6]);
            const fur2 = max - ((max-min)*fibo[5]);
            const fur3 = max - ((max-min)*fibo[4]);
            const fur4 = max - ((max-min)*fibo[3]);
            const fur5 = max - ((max-min)*fibo[2]);
            const fur6 = max - ((max-min)*fibo[1]);
            const fur7 = max - ((max-min)*fibo[0]);
            return {trend : "UP", fr1 : fur1, fr2 : fur2, fr3 : fur3, fr4 : fur4, fr5 : fur5, fr6 : fur6, fr7 : fur7, firstPrice : first, lastPrice : last, minPrice : min, maxPrice : max}
        } else if (last-first<=0) {
            const fdr1 = min + ((max-min)*fibo[0]);
            const fdr2 = min + ((max-min)*fibo[1]);
            const fdr3 = min + ((max-min)*fibo[2]);
            const fdr4 = min + ((max-min)*fibo[3]);
            const fdr5 = min + ((max-min)*fibo[4]);
            const fdr6 = min + ((max-min)*fibo[5]);
            const fdr7 = min + ((max-min)*fibo[6]);
            return {trend : "DOWN", fr1 : fdr1, fr2 : fdr2, fr3 : fdr3, fr4 : fdr4, fr5 : fdr5, fr6 : fdr6, fr7 : fdr7, firstPrice : first, lastPrice : last, minPrice : min, maxPrice : max}
        }
    }

    return(
        <>{FibonacciRetracement()}</>
    )

};
