import React, {useEffect} from 'react'
import { connect, disconnect, send } from '@giantmachines/redux-websocket';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual  } from "react-redux";
import { useGetListQuery } from '../../services/cryptoQuery';

export const WebSockets = (data) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const uuid = data;

    const { data: cryptosList, isFetching } = useGetListQuery({count : 100, offset : 0});
    
    const cryptoUuids = cryptosList?.data?.coins?.slice(0,10).map((coin) => (coin.uuid))

    const isLoading = useSelector((state) =>state?.reducers?.webSockets?.isLoading, shallowEqual)

    useEffect(() => {      
        dispatch(connect(`wss://api.coinranking.com/v2/real-time/rates?x-access-token=coinranking0b5591991a10fd846492184d3952e39bef3d7905eadf6e68`));
        return () => {dispatch(disconnect())}; //marche pas
    }, [location]);

 
    useEffect(() => {   
        if (!isLoading && !isFetching) {
            dispatch(send({ throttle: '10s', uuids: uuid ? [uuid] : cryptoUuids}))
        }
    }, [isLoading, isFetching, location]);

}

    //timer est également triguer à chaque render

    //return ('anas')
    //return (JSON.stringify(dataTable))

    //return (
    //    WebSockets()
    //JSON.stringify(pricex)
    //<div><Button type="primary" size="medium" onClick={() => setRerender(rerender == 0 ? 1 : 0)}>HELLO</Button></div>
    //)

