import { SET_CURRENCY } from '../constants/actionTypes';

export const setCurrency = (currency = { value: 'HIVsRcGKkPFtW', label: 'USDT' }, action) => {
    switch (action.type) {
        case SET_CURRENCY:
            return action.payload;
        default:
            return currency;
    }
};

//rajouter start loading & end loading ?

//fatiguer à suivre demain, pourquoi la currency du store n'apparaît pas
//et comment faire avec l'uuid dorigine
//rajouter peut $etre {libellé, value} à la valeur par défaut currency, pour récupéer libellé dun coté et value de l'autre<