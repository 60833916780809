const webSockets = (state = {isLoading: true, history:[]}, action) => {

    switch (action.type) {
      
      case 'REDUX_WEBSOCKET::MESSAGE':
        // Assuming that your data is a DOMString in JSON format
        const data = JSON.parse(action.payload.message);
        return { ...state, 
          history : {...state.history, [data.currencyUuid] : {price : data.price, volume : data.volume}}
          //[data.currencyUuid] : {price : data.price, volume : data.volume} //mettre dans priceHistory
        }

      case 'REDUX_WEBSOCKET::OPEN':
        return { ...state, isLoading: false };

      case 'REDUX_WEBSOCKET::CLOSED':
        return { history:[], isLoading: true };
        
      default:
        return state
    }

};

export default webSockets;