import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Carousel, Typography, Avatar } from 'antd';

const user = JSON.parse(localStorage.getItem('profile'));

const Profil = () => {
  return (
    <>
        <Row className="profil-container" gutter={[4,4]}>
            <Col xl={24} xs={24}>
                <Avatar src={user?.result?.picture}/>
            </Col>
            <Col xl={24} xs={24}>
                <div>
                    <Typography.Text strong>Name : </Typography.Text>
                    <Typography.Text>{user?.result?.name}</Typography.Text>
                </div>
            </Col>
            <Col xl={24} xs={24}>
            <div>
                <Typography.Text strong>Email : </Typography.Text>
                <Typography.Text>{user?.result?.email}</Typography.Text>
            </div>
            </Col>
        </Row>
        <Row className="profil-password" gutter={[4,4]}>
            <Link>change password</Link>
        </Row>
    </>
  )
}

export default Profil