import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col} from 'antd';

import CryptoTreeMap from './CryptoTreeMap.js'
import { getLocalCoins } from '../../actions/localCoins';

const TreeMap = () => {

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getLocalCoins(null, 1000));
  }, [], dispatch);
  
  //comment envoyer la variable de l'autre côté ? 

  //console.log(treeMapCoins[0]?.data.filter(coin => coin.uuid === "WcwrkfNI4FUAe")[0].name)

  return (
    <>
      <CryptoTreeMap />
      <div id="treeMapChart">
        <Col >
          <svg id="chartSVG"></svg>
        </Col>
      </div>

    </>
  )
}

export default TreeMap
