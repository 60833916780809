import React, { useState } from 'react';
import {useNavigate , useLocation} from 'react-router-dom';
import axios from 'axios';
import { Form, AutoComplete } from 'antd';
import {fetchSuggestions} from '../../services/fetchSuggestions';

const {Option} = AutoComplete;

const SearchMenu = () => {
  const [suggestions, setSuggestions] = useState();
  const navigate = useNavigate ();
  const [form] = Form.useForm();
  const location = useLocation();

  const searchSuggestions = async (query) => {
    if (query && query.length >= 2) {
      const {data} = await axios.get(fetchSuggestions(query))
      return {data}
    }
  };

  const selectSuggestions = async (value) => {
    if (location.pathname.indexOf("CoinPage") > -1) {
      navigate(`/CoinPage/${value}`, { replace: true })
      form.resetFields(["autoComplete"]);
    } else {
      navigate(`/CoinPage/${value}`)
      form.resetFields(["autoComplete"]);
    } //utile pour retour browser
  }

  return (

    <Form form={form} >
      <Form.Item name="autoComplete">
        <AutoComplete
          id="autoComplete"
          placeholder="search for crypto"
          style={{
            width: 150,
          }}
          onChange={((query) => searchSuggestions(query)
          .then((result) => setSuggestions(result?.data?.data?.coins))
          )}
          onSelect={((value) => selectSuggestions(value))}
          allowClear={true}
        >
          {suggestions?.map((crypto) => (
            <Option
            id="options"
            key={crypto.uuid}
            value= {crypto.uuid}
            >
              {crypto.name}
            </Option>
          ))}
        </AutoComplete>
      </Form.Item>
    </Form>
  )
};

export default SearchMenu;