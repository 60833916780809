import { FETCH_LOCALCOINS, LIKE_LOCALCOIN, LOCALCOINS_START_LOADING, LOCALCOINS_END_LOADING} from '../constants/actionTypes';
import * as api from '../api';

export const getLocalCoins = (filter, limit) => async (dispatch) => {
    try {
        dispatch({ type: LOCALCOINS_START_LOADING });
        const { data } = await api.fetchLocalCoins(filter, limit);
        dispatch({ type: FETCH_LOCALCOINS, payload: data});
        dispatch({ type: LOCALCOINS_END_LOADING });
    } catch (error) {
        console.log(error.message)
    }
}

export const likeLocalCoin = (id, mode) => async (dispatch) => {
    try {
        const { data } = await api.likeLocalCoin(id, mode);
        dispatch({ type : LIKE_LOCALCOIN, payload: data});
    } catch (error) {
        console.log(error);
    }
}