import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'
import { Link } from 'react-router-dom';
import { Menu, Avatar, Button, Switch } from "antd";
import { UserOutlined, CodeOutlined, LogoutOutlined } from "@ant-design/icons";

import { setTheme } from '../../actions/setTheme';

const RightMenu = ({ mode }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch()
  const navigate = useNavigate ();
  const location = useLocation();
  const [switchTheme, setSwitchTheme] = useState(JSON.parse(localStorage.getItem('theme')))

  const logout = () => {
    dispatch({type: 'LOGOUT'})
    navigate('/');
    setUser(null);
  }


  const menuItems = [
    {
      key: 'user',
      label: <><Avatar icon={<UserOutlined />} alt={user?.result?.name} src={user?.result?.picture}>{user?.result?.name?.charAt(0)}</Avatar><span className="username">{user?.result?.name}</span></>,
      children: [
        {
          key: 'profil',
          icon: <UserOutlined />,
          label: <Link to="/profil">Profil</Link>
        },
        {
          key: 'portfolio',
          icon: <CodeOutlined />,
          label: <Link to="/portfolio">Portfolio</Link>
        },
        {
          key: 'log-out',
          icon: <LogoutOutlined />,
          label: 'Logout',
          onClick: logout,
        },
        {
          key: 'switch-theme',
          icon: <Switch id="switch-theme-button" checked={switchTheme} size="small" onChange={setSwitchTheme}/>,
          label: 'Dark Theme'
        }
      ],
    }
  ]

  useEffect(() => {
    const token = user?.token;

    if(token) {
      const decodedToken = jwtDecode(token)

      if(decodedToken.exp * 1000 < new Date().getTime()) logout(); //21h01
    }

    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location]);

  useEffect(() => {
    dispatch(setTheme(switchTheme));
    localStorage.setItem('theme', switchTheme);
  }, [switchTheme, location], dispatch);


  return (
    user ? (
      <Menu items={menuItems} mode={mode}/>
    ) : (
      <></>
    )
  );
};

export default RightMenu;