import { ALLTIMEHISTORY_START_LOADING, ALLTIMEHISTORY_END_LOADING, FETCH_ALLTIMEHISTORY } from '../constants/actionTypes';

const allTimeHistory = (state = { isLoading: true, allTimeHistory: [] }, action) => {
    switch (action.type) {
      case ALLTIMEHISTORY_START_LOADING:
        return { ...state, isLoading: true };
      case ALLTIMEHISTORY_END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALLTIMEHISTORY:
        return { ...state, allTimeHistory: action.payload };
      default:
        return state;
    }
  };

  export default allTimeHistory;