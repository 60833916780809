import { ADD_PORTFOLIO_LINE } from '../constants/actionTypes';

const addPortfolioLine = (addPortfolioLine = [], action) => {
    switch (action.type) {
        case ADD_PORTFOLIO_LINE:
            return action.payload;
        default:
            return addPortfolioLine;
    }
};//rajouter start loading & end loading ?

export default addPortfolioLine;