import axios from 'axios';

const API = axios.create({ baseURL: 'https://socoins-d3843d3c894b.herokuapp.com' });

//cacher base url sur process.env

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = JSON.parse(localStorage.getItem('profile')).token
    }

    return req;
})

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const googleUser = (result) => API.post('/user/google-user', result);

export const fetchLocalCoins = (filter, limit) => API.get(`/localcoins/${filter}/${limit}`);
export const getLocalCoin = (id) => API.get(`/localcoins/${id}/localCoin`);
export const likeLocalCoin = (id, mode) => API.patch(`/localcoins/${id}/like-localcoin/${mode}`);

export const fetchSelectedCoins = () => API.get(`/localcoins/selectedcoins`);

export const fetchPortfolio = (userId) => API.patch(`/portfolio`, userId);
export const createPortfolioLine = (newLine) => API.post(`/portfolio/new`, newLine);
export const deletePortfolioLine = (uuid) => API.delete(`/portfolio/${uuid}`)

export const fetchLunarCoinsListAgg = (name) => API.get(`/localLunar/coins/listAgg/${name}`);
export const fetchLunarCoinsList = (name) => API.get(`/localLunar/coins/list/${name}`);
export const fetchLunarCoinWeekV2 = (name) => API.get(`/localLunar/coins/${name}/time-series-week-v2`);
export const fetchLunarCoinYearV2 = (name) => API.get(`/localLunar/coins/${name}/time-series-year-v2`);
export const fetchLunarTopCoins = (limit, offset) => API.get(`/localLunar/coins/topcoins/${limit}/${offset}`);

export const fetchAllTimeHistory = (name) => API.get(`/alltimehistory/${name}`);

export const setTheme = (theme) => API.post('/theme/set', theme)