import React, { useContext, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Avatar, Input, Form, Typography } from 'antd';
import {NumericFormat} from 'react-number-format';

import { createPortfolioLine } from '../../actions/portfolio';
import { deletePortfolioLine } from '../../actions/portfolio';
import { likePortfolioLine } from '../../actions/portfolio';

const { Text } = Typography;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false);
  const [ptfLine, setPtfLine] = useState();
  
  
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (ptfLine) {dispatch(createPortfolioLine(ptfLine))} 
  }, [ptfLine]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const handleDispatch = (record) => {
    setPtfLine({uuid : record.uuid, quantity : record.quantity, price : record.buyPrice})
  };

  const Save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      const update = { ...record, ...values }
      record["total"] = update["quantity"]*update["price"]
      update["buyPrice"] ? record["pnl"] = update["quantity"]*update["price"]-update["quantity"]*update["buyPrice"] : record["pnl"] = '-' //remettre aussi dans le loading
      handleSave({ ...record, ...values });
      handleDispatch({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={Save} onBlur={Save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


const EditableTable = (localcoins) => {
  const [dataSource, setDataSource] = useState([...localcoins.localCoinsDataSource])
  const addPortfolioLine = useSelector((state) => state.reducers.addPortfolioLine);
  const dispatch = useDispatch()

  useEffect(() => {
    if (addPortfolioLine.uuid) {
      const favCoinsUuids = dataSource?.map(({ uuid }) => {
        return(uuid) })
      const include = favCoinsUuids.includes(addPortfolioLine.uuid)
      if (!include) {handleAdd(addPortfolioLine)} else {handleDelete(addPortfolioLine.uuid)} //sinon handledelet
    }
  }, [addPortfolioLine]);

  useEffect(() => {
    handlePnl()
    handleTotal()
  }, []); //vérifier comment alléger

  const handlePnl = () => {
    const newData = [...dataSource];
    newData.forEach(element => {
      element["buyPrice"] && element["quantity"] ? element["pnl"] = element["quantity"]*element["price"]-element["quantity"]*element["buyPrice"] : element["pnl"] = '-'
    setDataSource(newData)
    });
  };

  const handleTotal = () => {
    const newData = [...dataSource];
    newData.forEach(element => {
      element["buyPrice"] && element["quantity"] ? element["total"] = element["quantity"]*element["buyPrice"] : element["total"] = 0
    setDataSource(newData)
    });
  };

  const defaultColumns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      width: '10%',
      align:'center',
      sorter: (a, b) => a.key - b.key,
      defaultSortOrder : 'ascend'
    },
    {
      title: 'uuid',
      dataIndex: 'uuid',
      width: '10%',
      align:'center',
      hidden:true,
    },
    {
      title: '',
      dataIndex: 'icon',
      width: '5%',
      align:'center',
      render:icon => (<Avatar src={icon}/>)
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      width: '5%',
      align:'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      align:'center',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      width: '10%',
      align:'center',
      render:price => ( <NumericFormat value={price} displayType={'text'} thousandSeparator={true} decimalScale={2}/>)
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      width: '10%',
      align:'center',
      editable:true,
      render:quantity => ( <NumericFormat value={quantity} displayType={'text'} thousandSeparator={true} decimalScale={2}/>)
    },
    {
      title: 'BuyPrice',
      dataIndex: 'buyPrice',
      width: '10%',
      align:'center',
      editable:true,
      render:buyPrice => ( <NumericFormat value={buyPrice} displayType={'text'} thousandSeparator={true} decimalScale={2}/>)
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '20%',
      align:'center',
      render:total => ( <NumericFormat value={total} displayType={'text'} thousandSeparator={true} decimalScale={2}/>)
    },
    {
      title: '+/-',
      dataIndex: 'pnl',
      width: '20%',
      align:'center',
      render:pnl => ( <NumericFormat value={pnl} displayType={'text'} thousandSeparator={true} decimalScale={2}/>)
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (text, record) => (
        <span
          onClick={(e) => { handleDelete(record.uuid); }}
        >
          <a>Delete</a>
        </span>
      ),
    },
    
  ].filter(item => !item.hidden);

  const handleAdd = (value) => {
    const newData = {
      key: `temp${(Math.random().toFixed(5)*100000)}`,
      rank: '-',
      uuid: value.uuid,
      icon: value.iconUrl,
      symbol: value.symbol,
      name: value.name,
      price: value.price,
      quantity : 0,
      buyPrice : 0,
      total : 0,
      pnl : '-'
    };
    setDataSource([...dataSource, newData]);
    if (value.uuid) {dispatch(likePortfolioLine(value.uuid,"fav"))}
  };

  const handleDelete = async (key) => {
    
    const newData = dataSource.filter((item) => item.uuid !== key);
    setDataSource(newData);
    dispatch(likePortfolioLine(key, "fav"))
    dispatch(deletePortfolioLine(key));
    
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
  }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };  
  });
    
  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        //rowKey="id" //problème de unique child key
        bordered
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: '1500px' }}

        summary={(pageData) => {
          let grandTotal = 0;
          let totalPnl = 0;
  
          pageData.forEach(({ total, pnl }) => {
            grandTotal += total;
            totalPnl += pnl;
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell align='center'>Total</Table.Summary.Cell>
                <Table.Summary.Cell/><Table.Summary.Cell/><Table.Summary.Cell/><Table.Summary.Cell/><Table.Summary.Cell/><Table.Summary.Cell/>
                <Table.Summary.Cell align='center'>
                  <NumericFormat value={grandTotal} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                </Table.Summary.Cell>
                <Table.Summary.Cell align='center'>
                  <NumericFormat value={totalPnl} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        
      />
    </div>
  );
}

export default EditableTable

//ReactDOM.render(<P />, document.getElementById('container'));